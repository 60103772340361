<template>
   <div class="bg-white border rounded-md p-10 col-span-2">
      <div class="text-2xl border-b border-gray-100 pb-6 font-semibold">Passwords &amp; Security</div>
         <ValidationObserver v-slot="{ invalid }" tag="div" class="pt-5">
            <form class="flex flex-col justify-center mt-5" @submit.prevent="update()">
               <div class="grid grid-cols-1 gap-5">
                  <ValidationProvider tag="div" name="current password" rules="required|min:8" vid="confirmation" v-slot="{ errors }">
                     <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Current Password</label>
                     <input type="password" v-model="form.password_old" class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                        autocomplete="new-password" autocapitalize="off" spellcheck="false"
                        :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                     <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider tag="div" name="password" rules="required|min:8" vid="confirmation" v-slot="{ errors }">
                     <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">New Password</label>
                     <input type="password" v-model="form.password" class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                        autocomplete="new-password" autocapitalize="off" spellcheck="false"
                        :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                     <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider tag="div" name="confirm password" rules="required|confirmed:confirmation" v-slot="{ errors }">
                     <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Confirm Password</label>
                     <input type="password" v-model="form.password_confirmation" class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                        autocomplete="new-password" autocapitalize="off" spellcheck="false"
                        :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                     <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div class="relative">
                     <button type="submit" class="leading-4 inline-flex items-center cursor-pointer transition 
                        ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent rounded-md 
                        focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-4 md:px-6 bg-indigo-900 
                        hover:bg-indigo-800 px-5 mt-1.5" :disabled="invalid">
                        <svg v-show="isLoading" class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                           <!-- ... -->
                        </svg>Update Information</button>
                     </div>
                  </div>
            </form>
         </ValidationObserver>
   </div>
</template>

<script>
export default {
   data() {
      return {
         form: {},
         isLoading: false
      }
   },

   methods: {
      async update() {
         this.isLoading = true

         try{
            let res = this.$store.dispatch('auth/update_security', this.form)

            this.$toast.success(res.message)
            this.isLoading = false
         } catch(error) {
            this.isLoading = false
            this.$toast.error(error.response.message)
         }
      }
   }
}
</script>