var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white border rounded-md p-10 col-span-2"},[_c('div',{staticClass:"text-2xl border-b border-gray-100 pb-6 font-semibold"},[_vm._v("Passwords & Security")]),_c('ValidationObserver',{staticClass:"pt-5",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"flex flex-col justify-center mt-5",on:{"submit":function($event){$event.preventDefault();return _vm.update()}}},[_c('div',{staticClass:"grid grid-cols-1 gap-5"},[_c('ValidationProvider',{attrs:{"tag":"div","name":"current password","rules":"required|min:8","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Current Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_old),expression:"form.password_old"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"password","autocomplete":"new-password","autocapitalize":"off","spellcheck":"false"},domProps:{"value":(_vm.form.password_old)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password_old", $event.target.value)}}}),_c('span',{staticClass:"text-sm  text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","name":"password","rules":"required|min:8","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("New Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"password","autocomplete":"new-password","autocapitalize":"off","spellcheck":"false"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"text-sm  text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","name":"confirm password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_confirmation),expression:"form.password_confirmation"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"password","autocomplete":"new-password","autocapitalize":"off","spellcheck":"false"},domProps:{"value":(_vm.form.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password_confirmation", $event.target.value)}}}),_c('span',{staticClass:"text-sm  text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"relative"},[_c('button',{staticClass:"leading-4 inline-flex items-center cursor-pointer transition \n                     ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent rounded-md \n                     focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-4 md:px-6 bg-indigo-900 \n                     hover:bg-indigo-800 px-5 mt-1.5",attrs:{"type":"submit","disabled":invalid}},[_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"animate-spin h-5 w-5 mr-3 ...",attrs:{"viewBox":"0 0 24 24"}}),_vm._v("Update Information")])])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }